import { default as indexASDTbzqj7xMeta } from "/codebuild/output/src1508694115/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45invite9iDingLs5MMeta } from "/codebuild/output/src1508694115/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchasetKWYvZHdqEMeta } from "/codebuild/output/src1508694115/src/pages/account/extra-purchase.vue?macro=true";
import { default as indexc7PbxpRkNlMeta } from "/codebuild/output/src1508694115/src/pages/account/index.vue?macro=true";
import { default as settingsBD6S8HtrwhMeta } from "/codebuild/output/src1508694115/src/pages/account/settings.vue?macro=true";
import { default as subscriptionTs1bMU8g1yMeta } from "/codebuild/output/src1508694115/src/pages/account/subscription.vue?macro=true";
import { default as transactionswWjVEEuUNNMeta } from "/codebuild/output/src1508694115/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionP8bpy2HlD3Meta } from "/codebuild/output/src1508694115/src/pages/account/update-subscription.vue?macro=true";
import { default as previewcRy5zE3rLUMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composeJEWPQv1W4KMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as index3GebCEXBX2Meta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emailsKwYTo2zhROMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editYSqpSP9IxXMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsO5QzRaddZ7Meta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as indexBRFsunanCXMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsCwkjkRYeqFMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesr3dE9mpzBuMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as gallerydPjxukgF5JMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45details8N0d0AjKCdMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingsxgwTT899HQMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indexHXrDHq8pNxMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as membersS1IQ3mUQytMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editFwKwjhtPnoMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as indexjsBzD2G5sGMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newsXUvVPfEd3oMeta } from "/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93D6pVfDloCjMeta } from "/codebuild/output/src1508694115/src/pages/donate/[slug].vue?macro=true";
import { default as indexz2t0BA0gj6Meta } from "/codebuild/output/src1508694115/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribeOwA62kxHk2Meta } from "/codebuild/output/src1508694115/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_93oY6cXvh60HMeta } from "/codebuild/output/src1508694115/src/pages/events/[slug].vue?macro=true";
import { default as indexN9z6lNBUAfMeta } from "/codebuild/output/src1508694115/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordLAnk6ycKmWMeta } from "/codebuild/output/src1508694115/src/pages/forgot-password.vue?macro=true";
import { default as filesbpDdxsj7SPMeta } from "/codebuild/output/src1508694115/src/pages/groups/[slug]/files.vue?macro=true";
import { default as galleryI58b0Gqt1oMeta } from "/codebuild/output/src1508694115/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexe8V8e7okp7Meta } from "/codebuild/output/src1508694115/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shopU5CtEpmgIhMeta } from "/codebuild/output/src1508694115/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93ZUufKgbJJyMeta } from "/codebuild/output/src1508694115/src/pages/groups/[slug].vue?macro=true";
import { default as indexkHqTQCFPkCMeta } from "/codebuild/output/src1508694115/src/pages/groups/index.vue?macro=true";
import { default as indexNe7FqkC3HOMeta } from "/codebuild/output/src1508694115/src/pages/index.vue?macro=true";
import { default as joinWbTKPok43TMeta } from "/codebuild/output/src1508694115/src/pages/join.vue?macro=true";
import { default as login8VhUjVaMRgMeta } from "/codebuild/output/src1508694115/src/pages/login.vue?macro=true";
import { default as confirmationRBOPYLQR5EMeta } from "/codebuild/output/src1508694115/src/pages/membership/confirmation.vue?macro=true";
import { default as indexKZvBbY8PtvMeta } from "/codebuild/output/src1508694115/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93KnwMQBiXv5Meta } from "/codebuild/output/src1508694115/src/pages/news/[slug].vue?macro=true";
import { default as index2jPGM33O0WMeta } from "/codebuild/output/src1508694115/src/pages/news/index.vue?macro=true";
import { default as reset_45passwordMJWb3knAN7Meta } from "/codebuild/output/src1508694115/src/pages/reset-password.vue?macro=true";
import { default as setup_452faYqy93gotGaMeta } from "/codebuild/output/src1508694115/src/pages/setup-2fa.vue?macro=true";
import { default as shopILEeKSQrPVMeta } from "/codebuild/output/src1508694115/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src1508694115/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src1508694115/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchasetKWYvZHdqEMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexc7PbxpRkNlMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsBD6S8HtrwhMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionTs1bMU8g1yMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionswWjVEEuUNNMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionP8bpy2HlD3Meta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/account/update-subscription.vue")
  },
  {
    name: emailsKwYTo2zhROMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emailsKwYTo2zhROMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewcRy5zE3rLUMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composeJEWPQv1W4KMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: index3GebCEXBX2Meta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventsCwkjkRYeqFMeta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsCwkjkRYeqFMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editYSqpSP9IxXMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsO5QzRaddZ7Meta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: indexBRFsunanCXMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesr3dE9mpzBuMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: gallerydPjxukgF5JMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45details8N0d0AjKCdMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingsxgwTT899HQMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexHXrDHq8pNxMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: membersS1IQ3mUQytMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: newsXUvVPfEd3oMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newsXUvVPfEd3oMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editFwKwjhtPnoMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: indexjsBzD2G5sGMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src1508694115/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src1508694115/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src1508694115/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src1508694115/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src1508694115/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src1508694115/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_93ZUufKgbJJyMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src1508694115/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src1508694115/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src1508694115/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src1508694115/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src1508694115/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src1508694115/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1508694115/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src1508694115/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src1508694115/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src1508694115/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexKZvBbY8PtvMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src1508694115/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src1508694115/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src1508694115/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452faYqy93gotGaMeta || {},
    component: () => import("/codebuild/output/src1508694115/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src1508694115/src/pages/shop.vue")
  }
]