import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1508694115/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1508694115/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1508694115/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src1508694115/src/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1508694115/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src1508694115/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1508694115/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1508694115/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1508694115/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/codebuild/output/src1508694115/src/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import component_uid_lTFiHf6S3K from "/codebuild/output/src1508694115/src/plugins/component-uid.ts";
import custom_fEGFRb1IN3 from "/codebuild/output/src1508694115/src/plugins/custom.ts";
import directus_fdYmlw0WUJ from "/codebuild/output/src1508694115/src/plugins/directus.ts";
import fontawesome_klhsrycjcK from "/codebuild/output/src1508694115/src/plugins/fontawesome.js";
import handle_chunk_error_AuDE5rq9eh from "/codebuild/output/src1508694115/src/plugins/handle-chunk-error.ts";
import sentry_3AyO8nEfhE from "/codebuild/output/src1508694115/src/plugins/sentry.ts";
import v_calendar_dxaVzst4iS from "/codebuild/output/src1508694115/src/plugins/v-calendar.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  component_uid_lTFiHf6S3K,
  custom_fEGFRb1IN3,
  directus_fdYmlw0WUJ,
  fontawesome_klhsrycjcK,
  handle_chunk_error_AuDE5rq9eh,
  sentry_3AyO8nEfhE,
  v_calendar_dxaVzst4iS
]