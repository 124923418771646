import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!process.client) return;

  const { replayIntegration, extraErrorDataIntegration } = await import("@sentry/vue");
  Sentry.addIntegration(
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    })
  );

  Sentry.addIntegration(
    extraErrorDataIntegration({
      depth: 5,
    })
  );
}

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return [];

  const router = useRouter();
  const browserTracing = Sentry.browserTracingIntegration({ router });

  return [browserTracing];
}

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    const config = useRuntimeConfig();
    if (!config.public.SENTRY_DSN_PUBLIC) return;

    if (!__DEV__) {
      Sentry.init({
        app: vueApp,
        dsn: config.public.SENTRY_DSN_PUBLIC || undefined,
        integrations: getSentryIntegrations(),
        environment: config.public.SENTRY_ENVIRONMENT,

        beforeSend(event, hint) {
          if (
            hint.originalException instanceof Error &&
            (hint.originalException.message === "error loading dynamically imported module" ||
              hint.originalException.message === "NetworkError when attempting to fetch resource." ||
              hint.originalException.message.startsWith("Unable to preload CSS for") ||
              hint.originalException.message.startsWith("Importing a module script failed") ||
              hint.originalException.message.startsWith("Failed to fetch dynamically imported module"))
          ) {
            console.log("Skipping Sentry error for: ", hint.originalException.message);
            window.location.reload();
            return null;
          }
          return event;
        },

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // This sets the sample rate. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE,
      });
    }

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  },
});
